<template>
  <div>
    <div v-if="ready">

    <div class="d-block d-sm-none">
      <navigation-bottom-1/>
    </div>
    <div v-if="busy" style="width:100%;height:1200px;position:fixed;z-index:200;background: rgb(63,206,251);
background: radial-gradient(circle, rgba(63,206,251,1) 0%, rgba(12,14,140,1) 100%);">
        <p class="animated infinite bounce font-bold font-times text-2xl text-black" style="margin-top:50vh;margin-left:30vw;">LOADING ...</p>
    </div>
    <div class="text-center d-sm-none">
      <v-touch
        style="width:20px;background:transparent;height:100vh;position:absolute;left:0;z-index:10001;"
        v-on:swiperight="onSwipeRight"
        v-if="!menuTrigger"
      ></v-touch>
    </div>
    <div class="container-fluid anim " style="">
      <div class="row anim  bg-white ">
        <div
          @mouseenter="menuTrigger=true" @mouseleave="menuTrigger=false" 
          class="anim col-4 col-sm-3 col-md-2 "
          style="position:absolute;padding:0;margin:0;height:100vh;position:fixed;"
          :style="{'background':bgleft1}"
        >
          <!-- LEFT TOP -->
          <div
            style="height:0px;margin:none;padding-top:10px;"
            :style="{'background':bgleft1}"
            class="text-center text-white font-bold text-times text-xs"
          >
             <br>
            <br>
            <!-- <img src="@/static/logobtn.png" @click="$router.push('/admin')" class="kinoLightBox img-fluid " style=""> -->
         

          </div>
          <!-- LEFT SECOND -->
          <div class="p-1" :style="{'background':bgleft1}" style="z-index:100;">
              <p class="pl-2">
                  <!-- <span class="typcn typcn-leaf text-orange-300 text-2xl"></span> -->
                  <img v-if="$store.state.data.toko" :src="$store.state.data.toko.logo" alt="" class="d-inline" style="height:32px;width:32px;" v-viewer>
                  <span class="font-bold text-md  italic ml-4"
                  :style="`color:${$store.state.data.toko.warna_title}`"
                   v-if="$store.state.data.toko">{{$store.state.data.toko.nama_toko}}</span>
              </p>
            <!-- MENU BUTTON -->
            <!-- ============================= -->
            <div v-if="$store.state.users ">
            <div v-for="(item, index) in listMenu" :key="index+'listmenus'" v-show="item.level.includes($store.state.users.level)">
              <div  id="pertamaclick" class="bord hover:bg-orange-400  text-left pl-3 mt-3" style="font-size:13px;"
                @click="muncul($event);!menuTrigger?menuTrigger=!menuTrigger:''"
              >
                <span class="typcn " :class="`typcn-${item.icon}`" @click="parent($event)">
                  <span v-show="menuTrigger" class="pl-2 capitalize" >{{item.group}}</span>
                </span>
                <!-- <span class="typcn typcn-arrow-down float-right"></span> -->
              </div>
              <ul class="isaktif sm:pl-4 anim mb-3 text-white animated slideInRight " style="background:#232E30;" v-show="menuTrigger">
                <li 
                v-for="(item2, index2) in item.menu" :key="index2+'menu'"
                v-show="item2.level.includes($store.state.users.level)"
                class="bord hover:bg-orange-400 text-left sm:pl-3" style="font-size:13px;" 
                >
                <router-link :to="item2.link" style="color:white;">
                  <span v-html="item2.name.replace('<br>',' ')" class="mr-2"></span> <span class="typcn " :class="`typcn-${item2.icon}`"></span>
                </router-link>
                </li>
              </ul>
            </div>
            </div>
            <!-- ============================= -->
            <div
              class="bord hover:bg-orange-400  text-left pl-3 mt-2"
              style="font-size:13px;"
              @click="$router.push('/admin')"
            >
            <span class="typcn typcn-th-large"></span>
            <span v-if="menuTrigger">
              DASHBOARD
            </span>
            </div>
            <!-- END MENU -->
          </div>
        </div>
        <div
          class="bg-black anim"
          :class="{'col-8 col-sm-9 col-md-10 offset-4 offset-sm-3 offset-md-2':menuTrigger,'menutrigger col-12 col-sm-11':!menuTrigger}"
        >
          <div class="row">
            
            <!-- RIGHT TOP -->
            <div class="col-sm-12 " style="padding:0;margin:0;position:fixed;z-index:10000;" :style="`${$store.state.users.warna}`">
                <div class="row justify-content-between align-items-center shadow rounded-lg" style="height:50px;padding:3px;margin-left:0px;">
                    <div class="col-2 ">
                       
                        <span class="typcn typcn-th-menu text-2xl d-inline-block d-sm-none" @click="menuTrigger=!menuTrigger"></span>
                              
                        <div class="d-none d-sm-inline-block" v-if="$store.state.data.toko">
                            <span class="font-bold text-md  italic" :style="`color:${$store.state.data.toko.warna_title}`" v-if="$store.state.data">{{$store.state.data.toko.nama_toko}}</span>
                        </div>
                    </div>
                  <div class="col-6">
                  </div>
                  <div class="col-4 row justify-content-center">
                      <div class="col-2 d-none d-sm-inline-block" @click="notifs">
                          <!-- <button type="button" class="btn btn-sm btn-danger " v-if="notif>0" style="position:absolute;">{{notif}}</button>
                            <span class="text-lg typcn typcn-bell"></span> -->
                      </div>
                      <div class="col-2 d-none d-sm-inline-block">
                          <!-- <router-link to="/admin/transaksi/whistlist">
                            <span class="text-lg typcn typcn-shopping-cart"></span>
                          </router-link> -->
                      </div>
                      <div class="col-2 d-none d-sm-inline-block">
                          <!-- <router-link to="/admin/chat">
                          <button type="button" class="btn btn-sm btn-danger " v-if="notifchat>0" style="position:absolute;">{{notifchat}}</button>
                            <span class="text-lg typcn typcn-message"></span>
                          </router-link> -->
                      </div>
                      <div class="col-12 col-sm-6 ">
                            <span class="typcn typcn-user text-3xl" @click="logout"></span>
                            <span v-if="$store.state.users" @click="logout" class="ml-2 text-sm font-bold capitalize d-none d-sm-inline-block cursor-pointer">{{$store.state.users.name?$store.state.users.name:$store.state.users.nama?$store.state.users.nama:$store.state.users.username}}</span>
                      </div>
                  </div>
                </div>
            </div>
            <!-- RIGHT SECOND -->
            <div
              class="col-sm-12"
              style="background:white;min-height:100vh;padding:0;margin:0;"
              @click="menuTrigger=false;menuKanan=false"
            >
            <br>
            <br>
              <div class="sm:mt-2 pl-2 side-shadow "  style="min-height:90vh;">
                <div class="sm:pl-4" style="position:relative;">
                    <!-- <svg v-if="$store.state.data.toko" style="position:absolute;top:0px;left:-8px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path :fill="$store.state.data.toko.warna_admin" fill-opacity="1" d="M0,192L48,181.3C96,171,192,149,288,122.7C384,96,480,64,576,58.7C672,53,768,75,864,96C960,117,1056,139,1152,128C1248,117,1344,75,1392,53.3L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg> -->
                    <router-view style="position:absolute;z-index:100;width:100%;" v-myimage v-reload />
                   
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import uniqid from 'uniqid'
import firebase from 'firebase'
let db = firebase.firestore()
let rdb = firebase.database()
import autophp from '@/plugins/autophp_kasir.js'
let sdb = new autophp()
var id = require("date-fns/locale/id");
import navigationBottom1 from '@/components/navigationBottomAdmin.vue'
export default {
  components: {
    navigationBottom1
  },
 
   beforeMount() {
     this.ready=false;
      sdb.collection().ceklogin(this.$store).then(res=>{
        this.ready=true;
                //  this.$router.push('/lembur')
               }).catch(err=>{
                 this.$router.push('/admin/login')
               })
      let that = this;
    //   socket.on("new-message", message => {
    //   if(message.target_id==that.$store.state.users.id && message.app=='driver'){
    //       that.refreshNotif()
    //        //  CORDOVA
    //       document.addEventListener('deviceready', function () {
    //           cordova.plugins.notification.local.schedule({
    //               title: 'BTN Banjarbaru',
    //               text: message.text,
    //               sound:'file://resources/audio/beep.mp3'
    //           });
    //           cordova.plugins.notification.local.on('click', function (notification, eopts) { 
    //               setTimeout(() => {
    //                   that.$router.push(message.link)
    //               }, 1000);
    //           });
    //           navigator.notification.beep(1);
    //       })
    //   }
    // });
  },
  methods: {
    notifs(){
        sdb.collection("app_kasir_notifikasi",false).doc().select(`update app_kasir_notifikasi set dibaca='true' where tujuan='admin'`).then(res => {
          rdb.ref('/notifikasi/admin').set({uniq:uniqid()})
           this.$router.push('/admin/notifikasi')
        });
    },
    getToko(){
      sdb.collection("app_kasir_toko",false).doc().select(`select * from app_kasir_toko`).then(res => {
          this.toko=res;
          this.$store.state.data.toko=res[0];
          this.$forceUpdate();
      });
    },
    exits(){
      document.addEventListener("deviceready", function(e){
        navigator.app.exitApp();
      }, true);
    }, 
    logout () {
      this.$store.dispatch('logout')
      localStorage.removeItem('login')
      this.$router.push('')
    },
    muncul (e) {
      e.target.nextElementSibling.classList.toggle('isaktif')
    },
    parent(e){
      e.target.parentNode.click();
    },
    onSwipeRight () {
      this.menuTrigger = true
    },
    getListComment(){
      let that=this
            sdb.collection("app_kasir_chat",false).doc().select(`select * from app_kasir_chat`).then(res => {
                var unique = [...new Set(res.map((item) => item['username']))];
                unique=unique.map(e=>{
                    return {
                        username:e
                    }
                })
                unique.forEach((e,i)=>{ // [{id:1,status:'masuk'},{id:2,status:'keluar'}]
                    unique[i].data=[]; // [{id:1,status:'masuk',data:[]},{id:2,status:'keluar',data:[]}]
                    res.forEach(k=>{ // loop data yang ingin di join dengan uniq key 
                        if(e.username==k.username){ // gabungkan semua data di datanya yang status keluar dan masuk
                            unique[i].data.push(k); 
                        }
                    })
                })
                unique.forEach(e=>{
                    e.unread=e.data.filter(e=>{
                        if(e.dibaca=='false' && e.penerima=='admin'){
                            return e;
                        }
                    }).length
                })
                
                sdb.collection("tbuser",false).doc().select(`select * from tbuser where level!='user'`).then(res => {
                  let users=res.map(e=>{
                      return e.username
                  })
                  unique=unique.filter(e=>{
                  if(users.includes(e.username)){

                    }else{
                      return e
                    }
                  })
                    // console.log('unique',unique)
                    that.notifchat=unique.reduce((e,n)=>{
                      return e+parseInt(n.unread)
                    },0);
                    that.$forceUpdate();
                });
            });
        }
  },
  mounted () {
    let that=this
    this.getListComment();
    this.getToko();
      rdb.ref('/chat/admin').on('value',(e)=>{ //read
              this.getListComment();
          }) 
    rdb.ref('/notifikasi/admin').on('value',(e)=>{ //read
      sdb.collection("app_kasir_notifikasi",false).doc().select(`select * from app_kasir_notifikasi where tujuan='admin'`).then(res => {
          // console.log(res);
          that.notif=res.filter(e=>{
            if(e.dibaca=='false'){
              return e
            }
          }).length
          that.notifikasi=res;
      });
    }) 
    
  },
   data () {
    return {
      bgleft1: '#fff',
      test: 1,
      menuTrigger: true,
      menuKanan: false,
      ready: false,
      notifikasi:[],
      notif:0,
      notifchat:0,
      toko:[],
      busy: false, // digunakan untuk melihat apakah ada data yang masih diprocess untuk dimunculkan loading
      listMenu:[
        {
          group:'toko',
          icon:'home-outline',
          level:['SuperAdmin'],
          menu:[
                {
                    name:"Toko",
                    link:'/admin/toko/setting',
                    level:['SuperAdmin'],
                    icon:'home'
                },
                {
                    name:"Cabang Toko",
                    link:'/admin/toko/cabang',
                    level:['SuperAdmin'],
                    icon:'home'
                },
                {
                    name:"Modal",
                    link:'/admin/toko/modal',
                    level:['SuperAdmin'],
                    icon:'at'
                },
                {
                    name:"Penambahan dana",
                    link:'/admin/toko/penambahan',
                    level:['SuperAdmin'],
                    icon:'download'
                },
                {
                    name:"Pengeluaran dana",
                    link:'/admin/toko/pengeluaran',
                    level:['SuperAdmin'],
                    icon:'upload'
                },
                  {
                    name:"List Photo",
                    link:'/admin/toko/photo',
                    level:['SuperAdmin'],
                    icon:'upload'
                },
               
          ]
        },
         {
          group:'produk',
          icon:'dropbox',
          level:['SuperAdmin','Admin'],
          menu:[
                {
                    name:"produk/Barang",
                    link:'/admin/produk',
                    level:['SuperAdmin','Admin'],
                    icon:'dropbox'
                },
                {
                  name:"Kategori<br>Produk",
                    link:'/admin/produk/kategori',
                    level:['SuperAdmin','Admin'],
                    icon:'th-large'
                },
                {
                  name:"satuan<br>Produk",
                    link:'/admin/produk/satuan',
                    level:['SuperAdmin','Admin'],
                    icon:'sort-numerically'
                },
                {
                    name:"Barcode",
                    link:'/admin/produk/barcode',
                    level:['SuperAdmin','Admin'],
                    icon:'document'
                },
                // {
                //     name:"Diskon<br>Produk",
                //     link:'/admin/produk/diskon',
                //     icon:'tag'
                // },
          ]
        },
        {
          group:'Stok',
          icon:'download',
          level:['SuperAdmin','Admin'],
          menu:[
            {
                name:"Barang<br>Masuk/Beli",
                link:'/admin/stok/masuk',
                level:['SuperAdmin','Admin'],
                icon:'download'
            },
            {
                name:"Barang<br>Keluar",
                link:'/admin/stok/keluar',
                level:['SuperAdmin','Admin'],
                icon:'upload'
            },
          ]
        },
        {
          group:'supplier',
          icon:'group',
          level:['SuperAdmin','Admin'],
          menu:[
            {
                name:"Supplier",
                link:'/admin/supplier',
                level:['SuperAdmin','Admin'],
                icon:'group'
            },
          ]
        },
        {
          group:'Pelanggan',
          icon:'group-outline',
                level:['SuperAdmin','Admin','Kasir','adminapotek'],
          menu:[
            {
                name:"Pelanggan",
                link:'/admin/pelanggan',
                level:['SuperAdmin','Admin','Kasir','adminapotek'],
                icon:'group'
            },
             {
                name:"Membership",
                link:'/admin/pelanggan/membership',
                level:['SuperAdmin','Admin'],
                icon:'business-card'
            },
          ]
        },
        {
          group:'transaksi',
          icon:'shopping-cart',
          level:['SuperAdmin','Admin','Kasir','adminapotek','apoteker','dokter','nurse'],
          menu:[
            {
                name:"Transaksi",
                link:'/admin/transaksi/whistlist',
                level:[''],
                icon:'shopping-cart'
            },
             {
                name:"Update & Transfer",
                link:'/admin/transaksi/edit2',
                level:[''],
                icon:'arrow-loop'
            },
            {
                name:"Transaksi (image)",
                link:'/admin/transaksi/whistlist2',
                level:[''],
                icon:'image'
            },
            {
                name:"Edit",
                link:'/admin/transaksi/edit',
                level:[''],
                icon:'cog'
            },
             {
                name:"Online",
                link:'/admin/transaksi/online',
                level:['online'],
                icon:'world'
            },
            {
                name:"Transaksi Apotek",
                link:'/admin/transaksi/apotek',
                level:['SuperAdmin','Admin','Kasir','adminapotek','apoteker','dokter','nurse'],
                icon:'shopping-cart'
            },
            {
                name:"Ticket Flow",
                link:'/admin/transaksi/apotek/ticketflow',
                level:['SuperAdmin','Admin','Kasir','adminapotek','apoteker','dokter','nurse'],
                icon:'shopping-cart'
            },
            {
                name:"List Report",
                link:'/admin/report',
                level:['SuperAdmin','Admin','Kasir','adminapotek','apoteker','dokter','nurse'],
                icon:'chart-bar'
            },
          ]
        },
        {
          group:'Data Akhir',
          icon:'news',
          level:['SuperAdmin','Admin'],
          menu:[
            {
                name:"Update & Transfer Data",
                link:'/admin/transfer',
                level:['SuperAdmin','Admin'],
                icon:'arrow-loop'
            },
             {
                name:"Report",
                link:'/admin/transfer/report',
                level:['SuperAdmin','Admin'],
                icon:'business-card'
            },
          ]
        },
        // {
        //   group:'report',
        //   icon:'chart-bar',
        //   level:['SuperAdmin','Admin'],
        //   menu:[
            
        //   ]
        // },
        {
          group:'user',
          icon:'user',
          level:['SuperAdmin'],
          menu:[
            {
                name:"User<br>Pengguna",
                link:'/admin/user',
                level:['SuperAdmin'],
                icon:'group'
            },
          ]
        },
        {
          group:'Other',
          icon:'cog',
          level:['SuperAdmin'],
          menu:[
            {
                name:"Data Diagnosis",
                link:'/admin/apotek/diagnosis',
                level:['SuperAdmin'],
                icon:'cog'
            },
            {
                name:"Data Plan",
                link:'/admin/apotek/plan',
                level:['SuperAdmin'],
                icon:'cog'
            },
          ]
        }
      ]
    }
  },
}
</script>
<style scoped>
.isaktif {
  transition: all 0.3s ease-in-out;
  display: none;
}
.anim {
  transition: all 0.3s ease-in-out;
}
.bord {
  font-weight: bold;
  cursor: pointer;
  font-size: 17px;
  padding: 5px 0px 5px 0px;
  font-size: 14px;
}
.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 5px;
    padding-left: 10px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {
    background-color: #ddd;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
.side-shadow{
  /* border-left: 1px solid grey; */
    box-shadow: -1px -1px 2px 0 rgba(0, 0, 0, 0.1), -1px -1px 1px 0 rgba(0, 0, 0, 0.06);
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
      .menutrigger{
        margin-left:50px;
      }
    .righttopmenu{
      right:10px;
    }
 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
   
 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
    
 }

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    
 }
</style>
